<template>
  <div class="container-fixed">
    <div class="intro-box">
      <div>
        <div class="intro-box-title animate__animated animate__fadeInUp">Technical <br> Documentation</div>
        <div class="intro-box-sub animate__animated animate__fadeInUp">텍스트리는 고품질의<span style="color:#FFD966"> 기술 문서</span>가 제품의 가치를 더하고 소비자의 경험을 향상시킨다고 믿습니다.</div>
      </div>
    </div>
    <div class="intro-bottom">
      <div>
        <div class="intro-bottom-text">텍스트리는 20년 가까이 다양한 산업 분야의 기술 매뉴얼을 작성해 왔습니다. 당사의 기술 매뉴얼은 자동차, 반도체, 산업장비, 전자 제품 및 소프트웨어 응용 프로그램의 기술문서, 온라인 도움말 및 XML로 지원하고 있습니다. </div>
        <div class="intro-bottom-links">
          <a class="intro-bottom-link animate__animated animate__fadeInLeft" href="/documentation">
            <img src="/assets/icons/blank-document.png">
            <div>
              <div class="link-title">Documentation <br> Services</div>
              <div class="link-desc">기술 매뉴얼 제작 및 번역</div>
            </div>
            <img width="24" src="/assets/icons/arrow-right-yellow.png">
          </a>
          <a class="intro-bottom-link animate__animated animate__fadeInLeft animate__delay-1s" href="/solution">
            <img src="/assets/icons/network.png">
            <div>
              <div class="link-title">Document Management Solution Services</div>
              <div class="link-desc">문서 관리 솔루션 제공</div>
            </div>
            <img width="24" src="/assets/icons/arrow-right-yellow.png">
          </a>
          <a class="intro-bottom-link animate__animated animate__fadeInLeft animate__delay-2s" href="/portfolio">
            <img src="/assets/icons/suitcase.png">
            <div>
              <div class="link-title">TEXTree <br> Portfolio</div>
              <div class="link-desc">제작 및 지원 사례</div>
            </div>
            <img width="24" src="/assets/icons/arrow-right-yellow.png">
          </a>
          <span>Contact: 070-8270-3640 | contact@textree.co.kr</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  mounted () {
    store.commit('SET_NAV_THEME', 'light')
    store.commit('SET_MORE_THEME', 'light')
    store.commit('SET_MOBILE_MORE_FILL', true)
  }
}
</script>
<style scoped>
.container-fixed {
  background-image: url('/assets/images/homepage/home.png') !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: center;
}
.intro-box {
  color: rgba(255,255,255,.35);
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  border-left: 1px solid;
  border-right: 1px solid;
}
.intro-box > div {
  margin-top: -3em;
  width: 37.5%;
  height: 100%;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.intro-box-title {
  color: rgba(255,255,255,1);
  padding: 24px;
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 64px;
  line-height: 75px;
}
.intro-box-sub {
  color: rgba(255,255,255,1);
  padding: 24px;
  font-family: "Nanum Gothic";
  font-weight: bold;
  font-size: 30px;
  line-height: 50px;
}

.intro-bottom {
  color: white;
  position: absolute;
  top: calc(100% - 260px);
  bottom: 0;
  width: 100%;
}
.intro-bottom > div {
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #008ED6;
}
.intro-bottom > div::before {
  content: " ";
  position: absolute;
  left: 0;
  width: calc(50% - 800px);
  height: 100%;
  background: #008ED6;
}
.intro-bottom-text {
  padding: 24px;
  flex: 0 0 calc(37.5% - 48px);
  font-family: "Nanum Gothic";
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
}
.intro-bottom-links {
  flex: 0 0 calc(100% - 37.5% - 48px);
  padding: 0 24px;
  display: flex;
  justify-content: space-evenly;
  margin-top: -24px;
  position: relative;
  height: 50%;
}

.intro-bottom-links > span {
  position: absolute;
  bottom: -48px;
  left: 24px;
}

.intro-bottom-link {
  flex: 0 0 33%;
  display: flex;
  align-items: flex-start;
  text-decoration: none;
  color: white;
}
.intro-bottom-link > div {
  width: 175px;
}
.intro-bottom-link img {
  padding-right: 12px;
}
.link-title {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: bold;
}
.link-desc {
  font-family: "Nanum Gothic";
  font-size: 16px;
  font-weight: normal;
}

@media(max-width: 1440px) {
  .intro-bottom {
    top: calc(100% - 320px);
  }
  .intro-bottom > div {
    max-width: 100%;
  }
}
@media(max-width: 1280px) {
  .intro-box > div {
    height: calc(100% - 48px);
  }
  .intro-box-title {
    font-size: 48px;
  }
  .intro-box-sub {
    padding-top: 0;
    font-size: 24px;
  }
  .intro-bottom-link {
    flex-direction: column;
  }
  .intro-bottom-link img {
    padding-left: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .intro-bottom-text {
    line-height: 1.6;
  }
}
@media(max-width: 1024px) {
  .intro-bottom {
    top: calc(100% - 256px);
  }
  .intro-bottom-links {
    height: 65%;
  }
}

@media(max-width: 768px) {
  .container-fixed {
    background-position: bottom right;
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px);
  }
  .intro-box {
    width: 100%;
    height: auto;
    margin-top: calc(66px + 80px);
  }
  .intro-box > div {
    width: 100%;
    height: auto;
  }
  .intro-box-title {
    font-size: 24px;
    line-height: 1.5;
  }
  .intro-box-sub {
    font-size: 16px;
    line-height: 1.5;
  }
  .intro-bottom {
    position: relative;
    top: 0;
    flex: 1 0;
  }
  .intro-bottom > div {
    flex-direction: column;
    justify-content: normal;
  }
  .intro-bottom-text {
    font-size: 16px;
    flex: 0 1;
    padding-bottom: 0;
  }
  .intro-bottom-links {
    flex-direction: column;
    flex: 1 0;
    width: calc(100% - 48px);
  }
  .intro-bottom-link {
    flex-direction: row;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #FFFFFF;
  }
  .intro-bottom-link:last-child {
    border-bottom: none;
  }
  .intro-bottom-link > *:last-child {
    margin-left: auto;
  }
}

@media(max-width: 576px) {
  .container-fixed {
    background-position: 64%;
  }
}

@media(max-width: 425px) {
  .container-fixed {
    background-position: 64%;
  }

  .intro-box {
    width: 100%;
    height: auto;
    margin-top: calc(66px + 65px);
  }

  .intro-box-title {
    font-size: 35px;
    line-height: 1.5;
  }

  .intro-box-sub {
    font-size: 14px;
    line-height: 1.5;
  }

  .intro-bottom-text {
    font-size: 13px;
    flex: 0 1;
    padding-bottom: 0;
  }

  .link-title {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: bold;
  }

  .link-desc {
    font-family: "Nanum Gothic";
    font-size: 12px;
    font-weight: normal;
  }
}

@media(max-height: 680px) {
  .intro-box-title {
    font-size: 20px;
    line-height: 1.15;
    padding: 12px 24px;
  }
  .intro-box > div {
    margin-top: -4em;
  }
  .intro-box-sub {
    font-size: 12px;
    padding: 0 24px 12px;
  }
  .intro-bottom-text {
    font-size: 12px;
    padding: 12px 24px;
  }
  .link-title {
    font-size: 12px;
  }
  .link-desc {
    font-size: 9px;
  }
}
</style>
